
.course-form-actions {
  padding-top: 20px;
  display: flex;

  & a {
    font-size: 16px;
    font-weight: 600;
    color: var(--darkGray);

    &:before {
      content: "";
      height: 18px;
      width: 18px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      background-image: url('../assets/images/icons/arrow.svg');
    }

  }

  & a:not(.next) {
    padding-left: 22px;

    &:before {
      left: 0;
    }

  }

  & .next {
    padding-right: 22px;
    margin-left: auto;

    &:before {
      right: 0;
      transform: rotate(180deg);
    }

  }

}
