
two-column-layout {
  display: block;

  @media (--desktop-min) {
    display: flex;
    padding: 30px 0;
  }

  & > * {
    display: block;
    width: 100%;
  }

  & first-column {

    @media (--desktop-min) {
      width: 60%;
      margin-right: 10px;
    }

  }

  & second-column {

    @media (--tablet-max) {
      display: none;
    }

    @media (--desktop-min) {
      width: 40%;
    }

  }

  & second-column[mobile-visible="true"] {

    @media (--tablet-max) {
      display: block;
    }

  }

}
