
.actions {
  display: flex;
  justify-content: space-between;

  & > * {
    width: 48%;
  }

}
