
ballot-summary {
  display: block;

  & h1 {
    font-size: 18px;
    font-family: var(--sans);
    font-weight: 700;
    margin-bottom: 25px;
    position: relative;

    &[contains-error="true"] {
      color: var(--red);
      padding-left: 24px;
    }

    &[contains-error="true"]:before {
      content: "";
      display: inline-block;
      background-image: url('../assets/images/icons/alert.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 19px;
      width: 19px;
      left: 0;
      position: absolute;
    }

  }

  & h2 {
    font-size: 20px;
    font-family: var(--serif);
    font-weight: 700;
    margin-bottom: 25px;
  }

  & .info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &[ballot-page="true"] {
      align-items: flex-start;

      & .rating {
        margin-right: 20px;
      }

    }

    & .rating {
      margin-left: auto;
    }

  }

  & .left-aligned {

    & .rating {
      margin-left: 0;
      margin-right: 20px;
    }

  }

  & .category-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    & > div {
      width: 100%;
      border: 1px solid var(--brandGreen);
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-top: 20px;
      position: relative;
      transition: border 1s;

      & > div > div > svg {
        fill: var(--brandGreen);
        width: 40px;
        margin-right: 10px;
      }

      @media (--large-min) {
        width: calc(50% - 10px);
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:nth-of-type(2) {

        @media (--large-min) {
          margin-top: 0;
        }

      }

      &:nth-of-type(even) {

        @media (--large-min) and (--tablet-max) {
          margin-left: 20px;
        }

      }

      & span {
        display: block;
        margin-left: 10px;
        font-size: 16px;
        color: var(--mediumGray);
      }

      & img {
        z-index: 9;
        position: relative;
      }

    }
  }

  & a {
    color: var(--lightGreen);
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
  }

  & p {
    margin-bottom: 15px;
  }
}
