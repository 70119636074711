
.user-profile-image {
  height: 100px;
  width: 100px;
  background-position: center;
  background-size: cover;
  clip-path: circle(50% at 50% 50%);
  display: block;
  flex-shrink: 0;
}
