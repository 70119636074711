
@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Rg.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Rg.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Rg.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Rg.woff2) format("woff2");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_It.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_It.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_It.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_It.woff2) format("woff2");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBd.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBd.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBd.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBd.woff2) format("woff2");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBdIt.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBdIt.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBdIt.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_SBdIt.woff2) format("woff2");
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Bd.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Bd.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Bd.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_Bd.woff2) format("woff2");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Unify Serif';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_BdIt.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_BdIt.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_BdIt.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySerif_W_BdIt.woff2) format("woff2");
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Rg.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Rg.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Rg.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Rg.woff2) format("woff2");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_It.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_It.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_It.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_It.woff2) format("woff2");
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Bd.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Bd.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Bd.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_Bd.woff2) format("woff2");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_BdIt.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_BdIt.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_BdIt.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_BdIt.woff2) format("woff2");
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBd.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBd.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBd.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBd.woff2) format("woff2");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Unify Sans';
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBdIt.eot);
    src: url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBdIt.eot?#iefix) format("embedded-opentype"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBdIt.woff) format("woff"),
    url(https://www.gannett-cdn.com/gannett-web/global/fonts/unify/UnifySans_W_SBdIt.woff2) format("woff2");
    font-style: italic;
    font-weight: 700;
}

:root {

    --sans  : 'Unify Sans', Helvetica, Arial, sans-serif;
    --serif : 'Unify Serif', Georgia, Times, serif;

    --white: #fff;
    --black: #232323;
    --darkGrayTint: #626262;

    --red: #F44336;

    --brandGreen: #109889;
    --lightGreen: #1BBC9B;

    --lightGray: #f2f2f2;
    --disabledGray: #e3e3e3;
    --mediumGray: #787878;
    --darkGray: #404040;

    --borderRadius: 10px;

    font-family: var(--sans);
    font-size: 14px;
    line-height: 1.2em;
    color: var(--darkGrayTint);

    @media (--large-min) {
        font-size: 16px;
    }
}

.link {
    color: var(--lightGreen) !important;
}
