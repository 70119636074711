
update-form {

  & .photo-with-action {

    @media (--large-max) {
      margin-bottom: 30px;
    }

    @media (--tablet-min) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    & button {

      @media (--large-max) {
        display: block;
        margin: 0 auto;
        margin-bottom: 20px;
        max-width: 200px;
      }

    }

  }

}
