
@keyframes progress {
    0% {
        stroke-dasharray: 0 360;
    }
}

.rating {
    position: relative;

    & svg {
        transform: rotate(270deg);

        & .background {
            fill: none;
            stroke: var(--lightGray);
        }

        & .overlay {
            stroke: var(--lightGreen);
            fill: none;
            stroke-linecap: round;
            animation: progress 1s ease-out forwards;
        }

    }

    & p {
        font-family: var(--sans);
        font-weight: 700;
        background-color: transparent;
        border-radius: 50%;
        width: calc(100% - 14px);
        height: calc(100% - 14px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;

        & span {
            height: 5px;
            width: 50%;
            background-color: var(--darkGray);
        }

    }

}

.rating[small-size="true"] {

    & svg {
        height: 50px;
        width: 50px;
    }

    & p {
        font-size: 16px;
    }

}
