
@keyframes bounce {
  from { transform: translate3d(0, 0, 0);     }
  to   { transform: translate3d(0, 90px, 0); }
}

loading-animation {
  position: relative;
  display: block;

  & .loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba( 255, 255, 255, .9);
    display: block;
    z-index: 9999;
  }

  & ball-container {
    display: block;
    height: 200px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--brandGreen);
    overflow: hidden;
  }

  & bouncing-ball {
    display: block;
    height: 70px;
    width: 70px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    top: 20px;
    box-shadow: 1px 1px 3px 1px rgba( 0, 0, 0, .2);
    animation: bounce 0.56s cubic-bezier(0.55, 0.06, 0.68, 0.19);
    animation-direction: alternate;
    animation-iteration-count: infinite;
    z-index: 9;

    &:before {
      content: '';
      position: absolute;
      background-color: rgba( 0, 0, 0, .2 );
      height: 66px;
      width: 120px;
      transform: rotate(45deg);
      top: 53px;
      left: 25px;
      z-index: -1;
    }

    &:after {
      content: '';
      height: 70px;
      width: 70px;
      background-color: var(--white);
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin-left: -35px;
    }

  }

}
