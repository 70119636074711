
.number-pagination {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    & a {
        background-color: #D7D7D7;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 2%, rgba(0, 0, 0, 0.12) 100%);
        border-radius: 4px;
        padding: 12px;
        color: var(--white);
        text-align: center;
        transition: background 1s;
        font-size: 16px;
        font-weight: normal;
        display: flex;

        &.active {
            background-color: #109889;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 2%, rgba(0, 0, 0, 0.12) 100%);
        }

        &:hover {
            background-color: #109889;
            background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 2%, rgba(0, 0, 0, 0.12) 100%);
        }
    }
}
