
.call-to-action {
    background-color: #109889;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 2%, rgba(0,0,0,0.12) 100%);
    border-radius: 4px;
    padding: 12px;
    color: var(--white);
    text-align: center;
    align-self: flex-start;
    transition: background 1s;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;

    &:hover {
        background-color: var(--lightGreen);
    }
}
