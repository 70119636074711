
centered-form {
  background-color: var(--brandGreen);
  padding: 20px 0;
  min-height: 100vh;
  display: block;
  width: 100%;
  position: relative;

  @media (--tablet-min) {
    padding: 40px 0;
  }

  & .lock {
    width: 100%;
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & img {
    display: block;
    margin: 0 auto;
    margin-bottom: 80px;
  }

  & a {
    font-size: 12px;
    font-style: italic;
    display: block;
    text-align: center;
    color: var(--white);
    margin-top: 20px;
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="password"] {
    background-color: var(--white);

    &::placeholder {
      font-size: 16px;
      color: var(--brandGreen);
    }

  }

  & input[type="submit"] {
    background-color: #0A5E54;
    width: 100%;
  }

  & .error {
    margin-bottom: 30px;
    text-align: center;

    & p {
      color: var(--white);
    }

  }

}
