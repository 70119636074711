
course-comment-rating {

  & textarea {
    resize: none;
    width: 100%;
    height: 250px;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }

}
