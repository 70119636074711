
.center-action {
  display: flex;
  justify-content: center;

  & .call-to-action {
    padding-left: 30px;
    padding-right: 30px;

    &.xs {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 12px;
    }
  }

}
