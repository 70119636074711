
range-slider {
  display: block;
  height: 20px;
  position: relative;
  z-index: 9999;

  &:before {
    content: "";
    position: absolute;
    background-color: var(--lightGray);
    top: 0;
    left: -20px;
    right: -20px;
    height: 1px;
  }

  & p {
    position: absolute;
    top: -4px;
    left: 0;
    height: 80px;
    margin-top: -30px;
    cursor: all-scroll;
    z-index: 99999;
    display: flex;
    justify-content: center;
    margin-left: -6px;

    &[show-disabled="true"] {
      opacity: .2;
      cursor: inherit;
    }

  }

  & span {
    display: block;
    height: 20px;
    width: 20px;
    margin-top: 30px;
    background-image: url('../assets/images/icons/range-slider.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

}
