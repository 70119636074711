/*@import "animate.css";*/

component-search,
component-search.view-block {
    background-color: var(--brandGreen);
    display: block;
    color: var(--white);
    margin-bottom: 0;
    position: relative;

    @media (--tablet-max) {
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
    }

    @media (--desktop-min) {
        box-shadow: 0 -1px 5px -2px #c1c1c1;
        border-radius: var(--borderRadius) var(--borderRadius) 0 0;
    }

    &:before {

        @media (--tablet-max) {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -20px;
            right: -20px;
            background-color: var(--brandGreen);
        }

    }

    & h1 {
        font-size: 24px;
        font-family: var(--sans);
        font-weight: 700;
        margin-bottom: 20px;
        color: var(--white);

        @media (--tablet-min) {
            font-size: 32px;
        }

    }

    & form {
        display: relative;
    }

    & form > div:not(.results) {
        display: flex;
    }

    & input[type="text"] {
        margin-bottom: 0;
        color: var(--darkGrayTint);

        &::-webkit-input-placeholder {
        color: var(--brandGreen);
    }

    }

    & input[type="submit"] {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: transparent;
        z-index: 99;
        height: inherit;
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
        cursor: pointer;
        border: 1px solid var(--brandGreen);
        border-left: none;
        padding: 0;
        width: 100%;
    }

    & .submit-with-arrow {
        position: relative;
        background-color: var(--white);
        margin-left: 1px;
        border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
        flex-shrink: 0;
        transition: background 1s;

        &:hover {
            background-color: var(--lightGray);
        }

        @media (--large-max) {
            width: 44px;
        }

        @media (--tablet-min) {
            width: 140px;
        }

        &:after {

            @media (--large-max) {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                margin-left: -3px;
                display: block;
                height: 10px;
                width: 10px;
                border: 2px solid var(--brandGreen);
                border-left-color: transparent;
                border-bottom-color: transparent;
            }

        }

        & span {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            color: var(--darkGray);
            font-size: 16px;
            font-family: var(--sans);
            font-weight: 600;

            @media (--large-max) {
                display: none;
            }

        }

    }

}

component-search .results {
    position: absolute;
    left: 0;
    background-color: var(--white);
    border: 1px solid var(--brandGreen);
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    border-top: none;
    z-index: 99;
    padding: 20px;
    padding-bottom: 10px;
    display: none;
    overflow-y: scroll;

    @media (--large-max) {
        width: calc(100%);
    }

    @media (--tablet-min) {
        width: calc(100%);
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 1px;
        right: 1px;
        display: block;
        height: 1px;
        background-color: var(--lightGray);
    }

    & h2 {
        font-weight: 700;
        font-size: 20px;
        font-family: var(--serif);
        color: var(--darkGray);
        margin-bottom: 15px;
    }

    & .wrapper > .flex-close {
        margin-bottom: 20px;
    }

    & .wrapper > .flex-close p {
        font-weight: 700;
        font-size: 18px;
        color: var(--darkGray);
        padding-top: 20px;
    }

    & .wrapper > .flex-close button {
        color: var(--darkGray);
        margin-left: auto;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;

        @media (--large-min) {
            float: right;
        }

    }

    & .wrapper > div:not(.flex-close) {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--lightGray);

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

    }

    & .wrapper .close-disclaimer {
        margin-top: -10px;
    }

    & .wrapper .close-disclaimer button {
        text-align: center;
        width: 100%;
        display: block;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        padding: 10px 0;
    }

    & .small-text-blocks {
        margin-bottom: 0;
        color: var(--darkGray);
    }

}

component-search[with-results="false"] {

    & .results {
        display: none;
    }

}

component-search[with-results="true"] {

    & input[type="text"] {
        border-radius: var(--borderRadius) 0 0 0;
        border-bottom: none;
    }

    & .results {
        display: block;
    }

    & input[type="submit"] {
        bottom: -1px;
    }

}

component-search[with-space] {
    border-radius: var(--borderRadius);
    margin-bottom: 10px;
}

component-search + .view-block {

    &:after {
        display: none;
    }

}

component-search .requesting-ratings {
    margin-left: 15px;
    width: 20px;
    height: 20px;
}
