
.headline-with-action {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (--large-min) {
    margin-bottom: 30px;
  }

  & h1 {
    margin-bottom: 0;
    margin-right: 20px;
  }

  & p {
    margin-left: auto;
  }

  & a,
  & button {
    color: var(--lightGreen);
    font-size: 14px;
    font-weight: 600;
    margin-left: 7px;
    padding-left: 7px;
    display: inline-block;
    position: relative;
    white-space: nowrap;

    @media (--large-max) {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 0;
      width: 1px;
      background-color: var(--darkGray);
    }

    &:first-child {
      margin-left: 0;
      padding-left: 0;

      @media (--large-max) {
        display: block;
      }

      &:before {
        display: none;
      }

    }

  }

  & button.filter {

    @media (--tablet-min) {
      display: none;
    }

  }

}
