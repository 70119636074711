
user-profile,
user-profile.view-block {
  position: relative;

  @media (--medium-max) {
    display: none;
  }

  @media (--tablet-max) {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
  }

  &:before {

    @media (--tablet-max) {
      content: "";
      position: absolute;
      left: -20px;
      right: -20px;
      top: 0;
      bottom: 0;
      background-color: var(--white);
      box-shadow: 0 -1px 5px -2px #c1c1c1;
    }

  }

  & section {
    overflow: hidden;
  }

  & h1 {
    font-family: var(--serif);
    font-size: 32px;
    color: var(--darkGray);
    line-height: 1.2em;
    font-weight: bold;
  }

  & h2 {
    margin-bottom: 20px;
    color: var(--darkGrayTint);
    letter-spacing: 0.22px;
    line-height: 24px;
  }

  & img,
  & .user-profile-image {
    float: right;
  }

  & .info {
    margin-bottom: 10px;
    width: 100%;
  }

  & .description {
    font-size: 16px;

    @media (--large-max) {
      display: none;
    }

  }

}

user-profile[columns="true"] {

  & section {
    display: flex;
  }

  & img,
  & .user-profile-image {
    align-self: flex-start;
    margin-right: 20px;

    @media (--medium-max) {
      order: 2;
    }

  }

  & .actions {
    display: flex;

    & .call-to-action {
      display: none;

      @media (--tablet-min) {
        display: block;
      }

    }

  }

  & > .call-to-action {
    width: 100%;
    display: block;
    position: relative;

    @media (--tablet-min) {
      display: none;
    }

  }

}

user-profile[mobile-visible="true"] {

  @media (--medium-max) {
    display: block;
  }

}

user-profile + .view-block {

  &:after {
    display: none;
  }

}
