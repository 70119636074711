
.row {

  @media (--tablet-min) {
    display: flex;
    justify-content: space-between;
  }

  & > * {
    width: 100%;

    @media (--tablet-min) {
      width: calc(50% - 10px);
    }

  }

}

.row[viewport="small"] {
  display: flex;
  justify-content: space-between;

  & > * {
    width: calc(50% - 10px);
  }

}
