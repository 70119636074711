
number-slider {
  display: block;
  display: flex;
  padding: 60px 20px;
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  overflow: hidden;

  @media (--large-min) {
    padding: 80px 25px;
  }

}

/* Buttons */

number-slider button {
  display: block;
  cursor: pointer;
  width: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 99;
  background-color: var(--white);

  @media (--tablet-min) {
    width: 22px;
  }

  &:disabled {
    opacity: .2;
  }

  &:first-of-type {
    left: 0;

    & span {
      transform: rotate(-135deg) translateY(-50%);
      left: 15px;
      margin-top: -5px;
    }

  }

  &:last-of-type{
    right: 0;

    & span {
      transform: rotate(45deg) translateY(-50%);
      right: 15px;
    }

  }

  &:hover span:not(.sr-only) {
    border: 1px solid var(--brandGreen);
    border-bottom-color: transparent;
    border-left-color: transparent;

    @media (--large-min) {
      border: 3px solid var(--brandGreen);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

  }

  & span:not(.sr-only) {
    height: 15px;
    width: 15px;
    border: 2px solid var(--darkGray);
    border-bottom-color: transparent;
    border-left-color: transparent;
    transition: border .5s, opacity .5s;
    position: absolute;
    top: 50%;

    @media (--large-min) {
      height: 20px;
      width: 20px;
      border: 3px solid var(--darkGray);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

  }

}

/* Slide Wrapper */

number-slider slider-wrapper {
  width: calc(100% - 20px);
  margin: 0 auto;
  position: relative;
  text-align: center;
  display: block;

  & overflow-wrapper {
    display: flex;
    visibility: hidden;
    white-space: nowrap;
  }

}

number-slider slider-slide {
  font-family: var(--serif);
  display: inline-block;
  position: relative;

  &[disabled="true"] {

    & p {
      opacity: .4;
      transform: scale(.65);

      @media (--tablet-min) {
      }

    }

  }

  & p {
    transition: opacity 1s;
    line-height: 1em;
    font-size: calc(60px + 5vw);
    transform: scale(1);
    transition: transform .5s;
    color: var(--darkGray);
  }

  & span {
    display: block;
    font-size: 18px;
    font-weight: bold;
    opacity: 0;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @media (--tablet-min) {
      bottom: -50px;
    }

  }

}

number-slider slider-slide[active="true"] {
  transform: scale(1);
  opacity: 1;
  color: var(--darkGray);

  & span {
    opacity: 1;
    transition: opacity .5s;
  }

}
