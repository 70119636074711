
.columns {

    @media (--medium-max) {
        overflow-x: scroll;
        padding: 5px 0;
        margin-left: -5px;
    }

    & > div {
        display: flex;
    }

    @screen sm {
        & > div {
            @apply mb-10 border-red;
        }
    }

    & p {
        margin-bottom: 20px;
    }

    & a.small-headline-sans {
        color: var(--fontGray);
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
    }

    & .view-block {
        padding-bottom: 40px;
        position: relative;
        margin-bottom: 0;

        @media (--medium-max) {
            margin-left: 10px;
            width: 200px;
            flex-shrink: 0;
        }

        @media (--large-min) {
            margin-left: 20px;
            width: calc(25%);
        }

        &:before {

            @media (--large-min) {
                content: "";
                position: absolute;
                left: -20px;
                top: 0;
                bottom: 0;
                width: 1px;
                background-color: var(--lightGray);
            }

        }

        &:first-of-type {
            margin-left: 5px;
        }

        &:first-of-type:before {
            display: none;
        }

        & > *:last-child {
            margin-bottom : 0;
        }

        & > p:not(.small-headline-sans) {
            color: var(--fontGray);
            font-size: 14px;
            margin-bottom: 0;
        }

    }

}
