
course-select-rating,
course-select-rating.view-block {
  overflow: hidden;

  & h1 {
    line-height: 1em;
  }

  & number-controller {
    display: block;

    & .numbers {
      height: 32px;
      position: relative;

      & .wrapper {
        width: 100%;
        display: flex;
        display: flex;
        justify-content: space-between;
      }

      & button {
        display: block;
        text-align: left;
        font-size: 13px;
        font-weight: 700;
        font-family: var(--serif);
        color: var(--mediumGray);
        transform: scale(1);
        transition: color 1s, transform 1s, opacity 1s;
        opacity: 1;
        cursor: pointer;
        z-index: 99;
        user-select: none;

        &:hover {
          color: var(--darkGray);
          transform: scale(1.4);
        }

        &:disabled {
          opacity: .4;
        }

      }

    }

    & .colors {
      display: flex;
      justify-content: space-between;

      & > div {
        height: 2px;
        width: calc( 33.3% + 20px);
        background-color: #32A4D5;

        &:first-of-type {
          background-color: #E4703E;
          margin-left: -20px;
          width: calc( 33.3% + 5px);
        }

        &:last-of-type {
          background-color: #1FBD9E;
          margin-right: -20px;
          width: calc( 33.3% + 5px);
        }

      }

    }

  }

}
