
list-items {

  & .rows {

    & *:not(.rating-text) {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
    }

    & h2,
    & h2.medium-headline-serif {
      margin-bottom: 10px;
      color: var(--darkGray)
    }

    & p {
      color: var(--fontGray);
    }

    & .info {
        max-width: 75%;
    }

  }

  & .small-text-blocks {
    margin-bottom: 0;
  }

}

/* Rows that are blocks on mobile */

list-items .rows {

  & > .view-block {
    position: relative;

    @media (--large-min) {
      padding: 20px 0;
      margin-bottom: 0;
      border-bottom: 1px solid var(--lightGray);
    }

    &:last-of-type {

      @media (--tablet-min) {
        border-bottom: none;
      }

    }

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -20px;
      right: -20px;
      background-color: var(--lightGray);
      display: block;
      opacity: 0;
      transition: opacity 1s;
    }

    &:hover:before {
      opacity: 1;
    }

  }

}

/* Check for list without blocks on mobile (proifle page) */

list-items .rows {

  & > div:not(.view-block) {
      padding: 20px 0;
      margin-bottom: 0;
      border-bottom: 1px solid var(--lightGray);
      position: relative;

    &:last-of-type {
      border-bottom: none;
    }

  }

}

/* With Flex Alignment */

list-items .flex-align {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

/* With Rating */

list-items .rows {


}

/* With Close Button */

list-items .rows .view-block {

  & button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

}

/* With Chevron */

list-items .rows {

  & > div:hover {

    & .chevron {
      border: 2px solid var(--brandGreen);
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

  }

  & .chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    border: 2px solid var(--darkGray);
    border-bottom-color: transparent;
    border-left-color: transparent;
    transition: border 1s;
  }

}

/* With Date */

list-items .date {
  padding-right: 20px;
  min-width: 20%;
}

list-items .pagination {
  padding-top: 20px;
  display: flex;

  & a {
      font-size: 16px;
      font-weight: 600;
      color: var(--darkGray);

  &:before {
     content: "";
     height: 18px;
     width: 18px;
     background-position: center;
     background-size: contain;
     background-repeat: no-repeat;
     position: absolute;
     background-image: url('../assets/images/icons/arrow.svg');
   }
  }

  & a:not(.next) {
    padding-left: 22px;

    &:before {
       left: 0;
     }
  }

  & .next {
      padding-right: 22px;
      margin-left: auto;

  &:before {
     right: 0;
     transform: rotate(180deg);
   }

  }
}
