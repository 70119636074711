
.ballot-entry {

  & h1 {
    max-width: 80%;
  }

  & .ballot-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

.ballot-entry[error="true"] {
  border: 2px solid var(--red);
}
