
.lock {
  padding: 0 15px;
  max-width: 1600px;
  margin: 0 auto;

  @media (--tablet-min) {
    padding: 0 20px;
  }

  @media (--desktop-min) {
    padding: 0 30px;
  }

}
