
text-accordion,
text-accordion.view-block, {
  display: block;

  & [data-mce-selected] {
    color: var(--brandGreen);
    text-decoration: underline;
  }

  &:after {
    display: none;
  }

  & .expand {
    position: relative;
    padding-top: 20px;
    margin-top: 20px;

    &:before {
      content: "";
      position: absolute;
      height: 1px;
      background-color: var(--lightGray);
      top: 0;
      left: -20px;
      right: -20px;
    }

    & button {
      font-size: 16px;
      font-weight: 600;
      position: relative;
      padding-left: 30px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        background-image: url('../assets/images/icons/expand.svg');
        background-position: center;
        background-repeat: no-repeat;
      }

    }

  }

  & accordion-block {
    margin-top: 20px;
  }

}
