
accordion-block {
  display: block;
  margin-top: 20px;

  & accordion-wrapper {
    display: block;
    overflow: hidden;
    height: 0;
  }

  & wrap-height {
    display: block;
  }

}

accordion-button {
  position: relative;
  display: block;
  padding-top: 10px;
  margin-bottom: -5px;

  &:before {
    content: "";
    position: absolute;
    left: -20px;
    right: -20px;
    top: 0;
    height: 1px;
    background-color: var(--lightGray);
    display: block;
  }

  & button {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;

    & span {
      display: inline-block;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      border: 1px solid var(--lightGreen);
      margin-right: 5px;
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 7px;
        width: 1px;
        display: block;
        background-color: var(--lightGreen);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        border-radius: 5px;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(0deg);
        transition: transform 1s;
      }

    }

  }

}

accordion-block[expanded="true"] {

  & accordion-button button {

    & span:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

  }

}
