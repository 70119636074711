
header {
  background-color: var(--white);
  box-shadow: 0 0 5px 1px #c1c1c1;
  z-index: 99;
  display: block;
  min-width: 300px;

  & .lock {
    display: flex;
    align-items: center;

    @media (--large-max) {
      justify-content: space-between;
    }

    & > a {
      padding: 0;
    }

  }

  & img {
    max-height: 60px;
    height: 60px;
    padding: 10px 0;

    @media (--desktop-min) {
      max-height: 80px;
    }

  }

  & a,
  & button {
    font-size: 14px;
    color: var(--darkGray);
    padding: 0 8px;
    font-family: var(--sans);
    font-weight: 700;
    cursor: pointer;

    @media (min-width: 768px) and (max-width: 850px) {
      font-size: 12px;
    }

    @media (--desktop-min) {
      padding: 0 15px;
      font-size: 15px;
    }

  }

  & button[aria-controls="profile"],
  & button[aria-controls="menu"] {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    @media (--tablet-min) {
      display: none;
    }

    &:disabled {
      opacity: .3;
    }

  }

  & button[aria-controls="profile"] {
    background-image: url('../assets/images/icons/profile.svg');
    height: 30px;
    width: 30px;
  }

  & button[aria-controls="menu"] {
    background-image: url('../assets/images/icons/menu.svg');
    height: 20px;
    width: 20px;
  }

  & button[aria-controls="header-profile"] {
    position: relative;
    height: 100%;
    padding-right: 0;

    @media (--large-max) {
      display: none;
    }

    & span {
      display: block;
      padding-right: 25px;
    }

    &:before,
    &:after {
      content: "";
      background-color: var(--brandGreen);
      height: 10px;
      width: 2px;
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -3px;
      transform: rotate(-45deg);
    }

    &:after {
      right: 9px;
      transform: rotate(45deg);
    }

  }

}

/* Header Nav */

header nav {

  @media (--tablet-min) {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 80px;
  }

  & a,
  & button {
    display: flex;
    align-items: center;
    position: relative;

    @media (--large-max) {
      background-color: var(--white);
      padding-left: 60px;
      align-self: center;
      transition: background-color .5s, color .5s;
      height: 80px;
    }

    @media (--tablet-min) {
      height: 100%;
    }

    &:hover {

      @media (--large-max) {
        background-color: var(--brandGreen);
        color: var(--white);

      }

    }

    & img {
      display: none;

    }

  }

}

/* Mobile Actions */

header .mobile-actions {

  @media (--large-max) {
    width: calc(100vw - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 280px;
  }

}

/* Mobile Menu */

header .mobile-menu {

  @media (--large-max) {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 280px;
    height: 100vh;
    background-color: var(--white);
    display: none;
    transition: transform 1s;
    overflow-y: scroll;
  }

}

header #menu {

  @media (--large-max) {
    right: 0;
    transform: translateX(300px);
    box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, .1);
  }

}

header #profile {

  @media (--large-max) {
    left: 0;
    transform: translateX(-300px);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .1);
  }

}

header #menu[aria-hidden="false"] ,
header #profile[aria-hidden="false"] {

  @media (--large-max) {
    transform: translateX(0);
  }

}

header #menu {

  @media (--tablet-min) {
    display: flex;
    align-items: center;
  }

}


/* Header Dropdown  */

header header-dropdown {

  @media (--tablet-min) {
    position: relative;
    height: 100%;
    z-index: 999;
  }

  & div {

    @media (--tablet-min) {
      position: absolute;
      right: -10px;
      top: 80px;
      background-color: var(--white);
      box-shadow: 0px 5px 5px -1px #c1c1c1;
    }

    &[aria-hidden="true"] {
      display: none;
    }

    &[aria-hidden="false"] {
      display: block;
    }

    & a,
    & button {

      @media (--tablet-min) {
        white-space: nowrap;
        padding: 15px 40px;
        display: block;
        border-bottom: 1px solid var(--lightGray);
        width: 100%;
        text-align: left;
      }

      &:last-child {

        @media (--tablet-min) {
          border-bottom: 0;
        }

      }

    }

  }

}

/* Close Buttons */

Header .close-wrapper {
  position: relative;

  @media (--large-max) {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (--tablet-min) {
    display: none;
  }

  & button {
    height: 50px;
    width: 50px;
    display: block;
  }

}

/* Mobile Utility */

header .mobile-utility {

  @media (--large-max) {
    border-top: 1px solid var(--lightGray);
  }

  @media (--tablet-min) {
    display: none;
  }

  & a {

    @media (--large-max) {
      padding-left: 20px;
      height: 40px;
      justify-content: flex;
      align-items: center;
      font-size: 12px;
    }

    &:hover {
      background-color: var(--lightGray);
    }

  }

}
