
.view-block {
  border-radius: var(--borderRadius);
  padding: 15px;
  box-shadow: 0 0 5px 1px #c1c1c1;
  background-color: var(--white);
  margin-bottom: 10px;
  display: block;
  position: relative;

  @media (--tablet-min) {
    padding: 20px;
  }

  &:after {

    @media (--large-max) {
      content: "";
      display: block;
      height: 1px;
      background-color: #D7D7D7;
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
    }

  }

}

.view-block[block-viewport="small"] {

  @media (--large-min) {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    padding-bottom: 40px;
  }

  &:after {
    display: none;
  }

}

.view-block[block-viewport="large"] {

  @media (--medium-max) {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    position: relative;
    padding: 15px 0;
    margin-bottom: 0;
  }

}

.view-block[remove-radius="true"] {

  @media (--desktop-min) {
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  }

  &:before {
    display: none;
  }

}
