
form {

  & label {
    display: block;
    font-size: 18px;
    font-family: var(--sans);
    margin-bottom: 10px;
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="password"],
  & select,
  & textarea {
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    height: 60px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: var(--sans);
    border: 1px solid var(--mediumGray);

    &::placeholder {
      font-size: 16px;
      color: var(--mediumGray);
    }

  }

  & input:disabled {
    color: var(--disabledGray) !important;
    border-color: var(--disabledGray) !important;
  }

  & select {

  }

  & textarea {
    resize: none;
    height: 120px;
  }

  & input[type="submit"] {
    border-radius: 5px;
    height: 44px;
    background-color: var(--brandGreen);
    color: var(--white);
    border: none;
    font-family: var(--sans);
    font-size: 16px;
    padding: 0 40px;

    @media (--large-max) {
      width: 100%;
    }

    @media (--tablet-min) {
      padding: 0 60px;
    }

  }

  & .errors {
    display: none;
    margin-top: -15px;
    margin-bottom: 20px;
    color: var(--red);
    font-size: 12px;
    font-weight: 700;
  }

  & .image-upload {
    display: none;
  }

  span.help {
    font-size: 8px;
    font-style: italic;
  }

}

/* Required */

form input-wrapper[required="true"] {

  & label span {
    display: relative;
    display: inline-block;
  }

  & label span:after {
    content: "*";
    font-size: 24px;
    color: var(--brandGreen);
    margin-left: 3px;
  }

}

/* Error */

form input-wrapper[error="true"] {

  & label {
    color: var(--red);
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="password"],
  & select,
  & textarea {
    border: 1px solid var(--red);
  }

  & .errors {
    display: block;
  }

  & label span:after {
    color: var(--red);
  }

}

form .clubOptions {
  margin-bottom: 20px;

  & button {
    color: var(--lightGreen);
    font-size: 16px;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    margin-top: 15px;

    & span {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -2px;
      height: 4px;
      width: 18px;
      background-color: var(--lightGreen);
    }

    & span:first-of-type {
      transform: rotate(90deg);
    }

  }

  & input-wrapper {
    display: block;
  }

  & .input-dropdown:nth-of-type(1) {
    margin-top: 0px;
  }

  & .input-dropdown {
    position: relative;
    display: block;
    margin-top: 15px;
  }

  & .input-dropdown[ show-overlay="true" ] .results {
    display: block;

  }

  & .results {
    position: relative;
    margin-top: -23px;
    display: none;
  }

  & .wrapper {
    position: absolute;
    background-color: var( --white );
    z-index: 99;
    width: 100%;
    padding: 10px;
    border: 1px solid black;
    box-shadow: 0 3px 3px -1px rgba( 0, 0, 0, .3);
    max-height: 300px;
    overflow-y: scroll;

    & button {
      display: block;
      width: 100%;
      color: var(--darkGray);
      text-align: left;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--darkGray);
      padding-left: 0;
      font-size: 16px;

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: none;
      }

    }

    & .close {
      height: 40px;
      text-align: right;
      background-image: none;
      font-size: 16px;
    }

  }

}

/* Valid */

form input-wrapper[valid="true"] {

  & label {
    color: var(--lightGreen);
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="password"],
  & select,
  & textarea {
    border: 1px solid var(--lightGreen);
  }

}

.tooltip {
  position: absolute;
  top: -34px;
  background-color: var(--brandGreen);
  padding: 5px;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  margin-left: 50%;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;

  &.on {
    opacity: 1;
   }

  &:before {
     content: "";
     width: 0;
     height: 0;
     border-style: solid;
     border-width: 5px 5px 0 5px;
     border-color: var(--brandGreen) transparent transparent transparent;
     position: absolute;
     bottom: -5px;
     left: calc(50% - 10px);
   }
}

.creatable-container .creatable__control {
  min-height: 60px;
}

.creatable__input input {
  margin-bottom: 0 !important;
  height: 23px !important;
}

.creatable__control {
  border-color: var(--mediumGray);
}

.club-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.creatable__multi-value .exists {
  background: #109889;
  color: #fff;
}

.creatable__multi-value .exists svg {
  fill: #fff;
}

.css-b8ldur-Input {
  min-width: 20px;
}

.multivalue-wrapper {
  display: block;
}
