
.member-list {

  & > div,
  & > div.view-block {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--lightGray);
    overflow: hidden;

    &:last-of-type {
      border-bottom: 0;
    }

  }

  & > div:not(.view-block) {

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }

  }

  & img {
    float: right;
    height: 80px;
  }

  & .small-text-blocks {
    margin-bottom: 0;
  }

}
