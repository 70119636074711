
.date {
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  color: var(--darkGray);

   &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--lightGreen);
  }

  & span {
    font-size: 12px;
    font-weight: 700;
    display: block;
    line-height: 1em;
  }

  & p {
    font-size: 38px;
    font-family: var(--serif);
    color: var(--darkGray);
    line-height: 1em;
  }

}

.date[with-padding="true"] {
  padding-right: 50px;
  width: inherit;
}
