global-alert {
  display: none;
}

global-alert.view-block {
  background-color: var(--lightGreen);
  color: var(--white);
  font-weight: 700;
}

.fade-appear {
  opacity: 0.01;
  display: block;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 0;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
