
.large-headline-sans {
  font-size: 24px;
  font-weight: 700;
  font-family: var(--sans);
  margin-bottom: 15px;
  line-height: 1.1em;

  @media (--tablet-min) {
    font-size: 32px;
  }

  &[with-columns="true"] {
    display: flex;
    line-height: 1em;

    & span:first-of-type {
      margin-right: 5px;
    }

  }

  & a {
    color: currentColor;
  }

}

.medium-headline-sans {
  font-size: 18px;
  font-weight: 700;
  font-family: var(--sans);
  margin-bottom: 15px;

  @media (--large-min) {
    margin-bottom: 25px
  }

  & a {
    color: currentColor;
  }

}

.small-headline-sans {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--sans);
  margin-bottom: 20px;
  color: var(--darkGray);

  & a {
    color: currentColor;
  }

}

.warning-sans {
  font-size: 16px;
  font-family: var(--sans);
  font-weight: 600;
  color: var(--red);
  margin-bottom: 20px;
  line-height: 1em;

  & span {
    position: relative;
    padding-left: 25px;
    display: inline-block;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      height: 20px;
      width: 20px;
      background-image: url('../assets/images/icons/alert.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

  }

}

.large-headline-serif {
  font-size: 24px;
  font-family: var(--serif);
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.1em;
  color: var(--darkGray);

  @media (--tablet-min) {
    font-size: 28px;
  }

}

.regular-headline-serif {
  font-size: 18px;
  font-family: var(--serif);
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.1em;

  @media (--tablet-min) {
    font-size: 24px;
  }

}

.medium-headline-serif {
  font-size: 17px;
  font-family: var(--serif);
  font-weight: 700;
  margin-bottom: 10px;

  & a {
    color: currentColor;
  }

}

.small-headline-serif {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--serif);
  margin-bottom: 5px;
}

.title-icon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
