
alerts-block,
alerts-block.view-block {
  display: block;
  position: relative;
  border-radius: 0 var(--borderRadius) var(--borderRadius) var(--borderRadius);
  margin-top: 20px;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
  }

  &:before {
    border-radius: 50%;
    top: -11px;
    left: 0;
    border: 14px solid var(--white);
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
  }

  &:after {
    background-image: url('../assets/images/icons/alert.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    left: 5px;
    top: -8px;
    background-color: transparent;
  }

  & .decoration {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--red);
    height: 10px;
    margin-bottom: 0;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  }

  & button {
    position: absolute;
    top: 15px;
    right: 20px;
    height: 25px;
    width: 25px;
    background-image: url('../assets/images/icons/close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  & > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & h2 {
      margin-bottom: 10px;
    }

    & p {
      font-size: 15px;
      margin-bottom: 7px;
    }

    & span {
      font-size: 12px;
    }

  }

}
