
course-overview,
course-overview.view-block {
  overflow: hidden;

  @media (--tablet-max) {
    margin-top: 20px;
  }

  & .small-headline-sans {
    margin-bottom: 5px;
  }

  & .warning-sans {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 5px;
  }

  & .actions {
    margin-top: 40px;
  }

  & .description {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      height: 1px;
      background-color: var(--mediumGray);

      @media (--tablet-min) {
        left: 0;
        right: 0;
      }

    }

  }

  & .row {

    & > div:last-of-type {

      @media (--large-max) {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
      }

      & > * {

        @media (--large-max) {
          margin-right: 20px;
        }

      }

    }

    & p,
    & a {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }

    }

    & h3 {
      margin-bottom: 10px;
    }

    & a {
      color: var(--brandGreen);
      display: block;
    }

    & p {
      color: var(--darkGray);
    }

    & span {
      font-weight: 700;
    }

  }

}
