
progress-bar,
progress-bar.view-block {
  background-color: var(--darkGray);
  padding: 20px;
  color: var(--white);
  display: block;

  & section > div:not(.bar) {
    display: flex;
    margin-bottom: 10px;

    & > * {
      font-size: 14px;
      font-style: italic;

      &:last-child {
        margin-left: auto;
      }

    }

  }

  & h1 {
      color: var(--white);
  }

  & .bar {
    width: 100%;
    height: 15px;
    background-color: var(--lightGray);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    & span {
      position: absolute;
      background-color: var(--lightGreen);
      left: 0;
      top: 0;
      bottom: 0;
      width: 1%;
    }

  }

}
