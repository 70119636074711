
.small-text-blocks {
  margin-bottom: 20px;

  & > *:last-child {
    margin-bottom : 0;
  }

  & p {
    margin-bottom: 5px;
    line-height: 1.1em;
    font-size: 14px;
  }

  & a {
    color: currentColor;
  }

}

.small-text-blocks[without-margin="true"] {
  margin-bottom: 0;
}
