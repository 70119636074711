
category-selector {
    padding-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;

    & > button {
        width: 100%;
        border: 1px solid var(--mediumGray);
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
        transition: border 1s;

        &:before {
            content: "";
            position: absolute;
            background-image: url('../assets/images/icons/checkmark.png');
            background-repeat: no-repeat;
            background-position: center;
            height: 20px;
            width: 20px;
            right: 2px;
            top: 2px;
            display: none;
        }

        @media (--large-min) {
            width: calc(50% - 10px);
        }

        @media (--desktop-min) {
            width: calc(33.3% - 10px);
            margin-left: 15px;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &:nth-of-type(2) {

            @media (--large-min) {
                margin-top: 0;
            }

        }

        &:nth-of-type(3) {

            @media (--desktop-min) {
                margin-top: 0;
            }

        }

        &:nth-of-type(even) {

            @media (--large-min) and (--tablet-max) {
                margin-left: 20px;
            }

        }

        &:nth-of-type(3n + 1) {

            @media (--desktop-min) {
                margin-left: 0;
            }

        }

        & span {
            display: block;
            margin-left: 10px;
            font-size: 16px;
            color: var(--mediumGray);
        }

        & > div > div > svg {
            z-index: 9;
            position: relative;
            width: 40px;
            fill: var(--mediumGray);
        }

    }

    & button[active="true"] {
        border: 1px solid var(--brandGreen);

        &:before {
            display: block;
        }

        & > div > div > svg {
            z-index: 9;
            position: relative;
            width: 40px;
            fill: var(--brandGreen);
        }

    }

}
