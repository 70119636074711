* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overscroll-behavior: none;
  background-color: var(--lightGray);
}

main {
  position: relative;
  display: block;
  min-width: 300px;
  height: auto;
}

section {
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: normal;
  color: var(--darkGray);
}

p {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul {
  padding-left: 30px;
}

button {
  border: none;
  background-color: transparent;
}
